import { Navigate, useRoutes } from 'react-router-dom';
//
import { View403 } from 'src/sections/error';
import ServerErrorPage from 'src/pages/500';
import { mainRoutes } from './main';

import { authRoutes } from './auth';
import { dashboardRoutes } from './dashboard';
import { paths } from '../paths';
import { cmsRoutes } from './cms';
import { facebookRoutes } from './services/facebook-services';
import { youtubeRoutes } from './services/youtube-services';
import { tiktokRoutes } from './services/tiktok-services';
import { instagramRoutes } from './services/instagram-services';
import { twitterRoutes } from './services/twitter-services';
import { telegramRoutes } from './services/telegram-services';
import { shopeeRoutes } from './services/shopee-services';
import { lazadaRoutes } from './services/lazada-services';
import { googleRoutes } from './services/google-services';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={paths.support} replace />,
    },
    {
      path: paths.proxy.root,
      element: <Navigate to={paths.proxy.dataCenter1.purchased} replace />,
    },

    // Auth routes
    ...authRoutes,

    // Dashboard routes
    ...dashboardRoutes,

    // Facebook service routes
    ...facebookRoutes,

    // Youtube service routes
    ...youtubeRoutes,

    // TikTok service routes
    ...tiktokRoutes,

    // Instagram service routes
    ...instagramRoutes,

    // Twitter service routes
    ...twitterRoutes,

    // Telegram service routes
    ...telegramRoutes,

    // Shopee service routes
    ...shopeeRoutes,

    // Lazada service routes
    ...lazadaRoutes,

    // Google service routes
    ...googleRoutes,

    // CMS routes
    ...cmsRoutes,

    // Main routes
    ...mainRoutes,

    // No match 404
    { path: '*', element: <Navigate to="/404" replace /> },
    { path: '/403', element: <View403 /> },
    { path: '/maintenance', element: <ServerErrorPage /> },
  ]);
}
