import { useEffect, useMemo, useState } from 'react';
// routes
import { paths } from 'src/routes/paths';
// components
import SvgColor from 'src/components/svg-color';
import { useAuthContext } from 'src/auth/hooks';
import { ROLE } from 'src/utils/constants';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  cart: icon('ic_cart'),
  service: icon('ic_service'),
  facebook: icon('ic_facebook'),
  youtube: icon('ic_youtube'),
  tiktok: icon('ic_tiktok'),
  instagram: icon('ic_instagram'),
  twitter: icon('ic_twitter'),
  telegram: icon('ic_telegram'),
  shopee: icon('ic_shopee'),
  lazada: icon('ic_lazada'),
  google: icon('ic_google'),
  money: icon('ic_money'),
  order: icon('ic_order'),
  user: icon('ic_user'),
  banking: icon('ic_banking'),
  product: icon('ic_product'),
  proxy: icon('ic_proxy'),
  vps: icon('ic_vps'),
  support: icon('ic_support'),
  proxyManagement: icon('ic_proxy_management'),
  proxyVPSManagement: icon('ic_proxy_vps_management'),
  statistics: icon('ic_analytics'),
  cronjob: icon('ic_cronjob'),
};

const MKTLoginIcon = <SvgColor src="/logo/logo_single.svg" sx={{ width: 1, height: 1 }} />;

// ----------------------------------------------------------------------
const FACEBOOK_SERVICE = {
  title: 'Facebook',
  path: paths.facebook.root,
  icon: ICONS.facebook,
  children: [
    // like post
    {
      title: 'Tăng Like Bài Viết',
      path: paths.facebook.likePost,
      children: [
        { title: 'Gói 1', path: paths.facebook.likePost1 },
        { title: 'Gói 2', path: paths.facebook.likePost2 },
        { title: 'Gói 3', path: paths.facebook.likePost3 },
      ],
    },
    // like page
    {
      title: 'Tăng Like Trang',
      path: paths.facebook.likePage,
      children: [
        { title: 'Gói 1', path: paths.facebook.likePage1 },
        { title: 'Gói 2', path: paths.facebook.likePage2 },
        { title: 'Gói 3', path: paths.facebook.likePage3 },
        { title: 'Gói Ngoại Quốc', path: paths.facebook.likePage4 },
      ],
    },
    // review
    { title: 'Tăng Review Trang', path: paths.facebook.reviewPage },
    // check in
    { title: 'Tăng Check In Page', path: paths.facebook.checkInPage },
    // friend
    { title: 'Tăng Bạn Bè', path: paths.facebook.friend },
    // follow
    {
      title: 'Tăng Follow',
      path: paths.facebook.follow,
      children: [
        { title: 'Gói 1', path: paths.facebook.follow1 },
        { title: 'Gói 2', path: paths.facebook.follow2 },
        { title: 'Gói 3', path: paths.facebook.follow3 },
      ],
    },
    // comment
    {
      title: 'Tăng Comment',
      path: paths.facebook.comment,
      children: [
        { title: 'Gói 1', path: paths.facebook.comment1 },
        { title: 'Gói 2', path: paths.facebook.comment2 },
        { title: 'Gói 3', path: paths.facebook.comment3 },
      ],
    },
    { title: 'Tăng Like Comment', path: paths.facebook.likeComment },
    // live stream
    {
      title: 'Tăng Mắt Live Stream',
      path: paths.facebook.liveStream,
      children: [
        { title: 'Gói 1', path: paths.facebook.liveStream1 },
        { title: 'Gói 2', path: paths.facebook.liveStream2 },
        { title: 'Gói 3', path: paths.facebook.liveStream3 },
      ],
    },
    // vip like
    {
      title: 'Vip Like',
      path: paths.facebook.vipLike,
      children: [
        { title: 'Gói 1', path: paths.facebook.vipLike1 },
        { title: 'Gói 2', path: paths.facebook.vipLike2 },
      ],
    },
    { title: 'Vip Like Group', path: paths.facebook.vipLikeGroup },
    { title: 'Vip Comment', path: paths.facebook.vipComment },
    { title: 'Vip Live Stream', path: paths.facebook.vipLive },
    {
      title: 'Tăng View',
      path: paths.facebook.view,
      children: [
        { title: 'Gói View Vip', path: paths.facebook.vipView },
        { title: 'Gói View Thường', path: paths.facebook.normalView },
        { title: 'View 600k Phút ', path: paths.facebook.view600k },
      ],
    },
    { title: 'Tăng Share Vào Nhóm', path: paths.facebook.sharePostGroup },
    { title: 'Tăng Share Lên Profile', path: paths.facebook.sharePostWall },
    {
      title: 'Tăng Thành Viên Nhóm',
      path: paths.facebook.memberGroup,
      children: [
        { title: 'Gói 1', path: paths.facebook.memberGroup1 },
        { title: 'Gói 2', path: paths.facebook.memberGroup2 },
      ],
    },
  ],
};

const CMS_FACEBOOK_SERVICE = {
  title: 'Facebook',
  path: paths.cms.service.facebook.root,
  children: [
    // like post
    {
      title: 'Tăng Like Bài Viết',
      path: paths.cms.service.facebook.likePost,
      subIcon: ICONS.proxy,
      children: [
        { title: 'Gói 1', path: paths.cms.service.facebook.likePost1 },
        { title: 'Gói 2', path: paths.cms.service.facebook.likePost2 },
        { title: 'Gói 3', path: paths.cms.service.facebook.likePost3 },
      ],
    },
    // like page
    {
      title: 'Tăng Like Trang',
      path: paths.cms.service.facebook.likePage,
      children: [
        { title: 'Gói 1', path: paths.cms.service.facebook.likePage1 },
        { title: 'Gói 2', path: paths.cms.service.facebook.likePage2 },
        { title: 'Gói 3', path: paths.cms.service.facebook.likePage3 },
        { title: 'Gói Ngoại Quốc', path: paths.cms.service.facebook.likePage4 },
      ],
    },
    // review
    { title: 'Tăng Review Trang', path: paths.cms.service.facebook.reviewPage },
    // check in
    { title: 'Tăng Check In Page', path: paths.cms.service.facebook.checkInPage },
    // friend
    { title: 'Tăng Bạn Bè', path: paths.cms.service.facebook.friend },
    // follow
    {
      title: 'Tăng Follow',
      path: paths.cms.service.facebook.follow,
      children: [
        { title: 'Gói 1', path: paths.cms.service.facebook.follow1 },
        { title: 'Gói 2', path: paths.cms.service.facebook.follow2 },
        { title: 'Gói 3', path: paths.cms.service.facebook.follow3 },
      ],
    },
    // comment
    {
      title: 'Tăng Comment',
      path: paths.cms.service.facebook.comment,
      children: [
        { title: 'Gói 1', path: paths.cms.service.facebook.comment1 },
        { title: 'Gói 2', path: paths.cms.service.facebook.comment2 },
        { title: 'Gói 3', path: paths.cms.service.facebook.comment3 },
      ],
    },
    { title: 'Tăng Like Comment', path: paths.cms.service.facebook.likeComment },
    // live stream
    {
      title: 'Tăng Mắt Live Stream',
      path: paths.cms.service.facebook.liveStream,
      children: [
        { title: 'Gói 1', path: paths.cms.service.facebook.liveStream1 },
        { title: 'Gói 2', path: paths.cms.service.facebook.liveStream2 },
        { title: 'Gói 3', path: paths.cms.service.facebook.liveStream3 },
      ],
    },
    // vip like
    {
      title: 'Vip Like',
      path: paths.cms.service.facebook.vipLike,
      children: [
        { title: 'Gói 1', path: paths.cms.service.facebook.vipLike1 },
        { title: 'Gói 2', path: paths.cms.service.facebook.vipLike2 },
      ],
    },
    { title: 'Vip Like Group', path: paths.cms.service.facebook.vipLikeGroup },
    { title: 'Vip Comment', path: paths.cms.service.facebook.vipComment },
    { title: 'Vip Live Stream', path: paths.cms.service.facebook.vipLive },
    {
      title: 'Tăng View',
      path: paths.cms.service.facebook.view,
      children: [
        { title: 'Gói View Vip', path: paths.cms.service.facebook.vipView },
        { title: 'Gói View Thường', path: paths.cms.service.facebook.normalView },
        { title: 'View 600k Phút ', path: paths.cms.service.facebook.view600k },
      ],
    },
    { title: 'Tăng Share Vào Nhóm', path: paths.cms.service.facebook.sharePostGroup },
    { title: 'Tăng Share Lên Profile', path: paths.cms.service.facebook.sharePostWall },
    {
      title: 'Tăng Thành Viên Nhóm',
      path: paths.cms.service.facebook.memberGroup,
      children: [
        { title: 'Gói 1', path: paths.cms.service.facebook.memberGroup1 },
        { title: 'Gói 2', path: paths.cms.service.facebook.memberGroup2 },
      ],
    },
  ],
};

const YOUTUBE_SERVICE = {
  title: 'Youtube',
  path: paths.youtube.root,
  icon: ICONS.youtube,
  children: [
    // subscribe
    {
      title: 'Tăng Subscribe',
      path: paths.youtube.subscribe,
      children: [
        { title: 'Subscribe Việt', path: paths.youtube.subscribeViet },
        { title: 'Subscribe Ngoại', path: paths.youtube.subscribeNgoai },
      ],
    },

    // like video
    { title: 'Tăng Like Video', path: paths.youtube.likeVideo },

    // dislike video
    { title: 'Tăng Disike Video', path: paths.youtube.dislikeVideo },

    // view
    {
      title: 'Tăng View',
      path: paths.youtube.view,
      children: [
        { title: 'View Thường', path: paths.youtube.viewNormal },
        { title: 'View Social', path: paths.youtube.viewSocial },
        { title: 'View Native ADS 30k', path: paths.youtube.viewNativeADS30 },
        { title: 'View Native ADS 500k', path: paths.youtube.viewNativeADS500 },
      ],
    },

    // watch time
    { title: 'Tăng Giờ Xem', path: paths.youtube.watchTime },

    // like comment
    { title: 'Tăng Like Comment', path: paths.youtube.likeComment },

    // comment
    {
      title: 'Tăng Comment',
      path: paths.youtube.comment,
      children: [
        { title: 'Comment Việt', path: paths.youtube.commentViet },
        { title: 'Comment Tây', path: paths.youtube.commentNgoai },
      ],
    },
  ],
};

const CMS_YOUTUBE_SERVICE = {
  title: 'Youtube',
  path: paths.cms.service.youtube.root,
  children: [
    // subscribe
    {
      title: 'Tăng Subscribe',
      path: paths.cms.service.youtube.subscribe,
      children: [
        { title: 'Subscribe Việt', path: paths.cms.service.youtube.subscribeViet },
        { title: 'Subscribe Ngoại', path: paths.cms.service.youtube.subscribeNgoai },
      ],
    },

    // like video
    { title: 'Tăng Like Video', path: paths.cms.service.youtube.likeVideo },

    // dislike video
    { title: 'Tăng Dislike Video', path: paths.cms.service.youtube.dislikeVideo },

    // view
    {
      title: 'Tăng View',
      path: paths.cms.service.youtube.view,
      children: [
        { title: 'View Thường', path: paths.cms.service.youtube.viewNormal },
        { title: 'View Social', path: paths.cms.service.youtube.viewSocial },
        { title: 'View Native ADS 30k', path: paths.cms.service.youtube.viewNativeADS30 },
        { title: 'View Native ADS 500k', path: paths.cms.service.youtube.viewNativeADS500 },
      ],
    },

    // watch time
    { title: 'Tăng Giờ Xem', path: paths.cms.service.youtube.watchTime },

    // like comment
    { title: 'Tăng Like Comment', path: paths.cms.service.youtube.likeComment },

    // comment
    {
      title: 'Tăng Comment',
      path: paths.cms.service.youtube.comment,
      children: [
        { title: 'Comment Việt', path: paths.cms.service.youtube.commentViet },
        { title: 'Comment Tây', path: paths.cms.service.youtube.commentNgoai },
      ],
    },
  ],
};

const TIKTOK_SERVICE = {
  title: 'TikTok',
  path: paths.tiktok.root,
  icon: ICONS.tiktok,
  children: [
    // follow
    {
      title: 'Tăng Follow',
      path: paths.tiktok.follow,
      children: [
        { title: 'Follow Việt', path: paths.tiktok.followViet },
        { title: 'Follow Nhanh', path: paths.tiktok.followNhanh },
        { title: 'Follow Ngoại', path: paths.tiktok.followNgoai },
      ],
    },
    // like
    {
      title: 'Tăng Tim',
      path: paths.tiktok.likePost,
      children: [
        { title: 'Tim Việt', path: paths.tiktok.likePostViet },
        { title: 'Tim Ngoại', path: paths.tiktok.likePostNgoai },
      ],
    },

    // like favorite
    { title: 'Tăng Lưu Video', path: paths.tiktok.likeFavorite },

    // view
    { title: 'Tăng View Video', path: paths.tiktok.view },

    // share
    { title: 'Tăng Share', path: paths.tiktok.share },

    // comment
    { title: 'Tăng Comment', path: paths.tiktok.comment },

    // live stream
    {
      title: 'Live Stream',
      path: paths.tiktok.livestream,
      children: [
        { title: 'Tăng Tim Live Stream', path: paths.tiktok.likeLivestream },
        { title: 'Tăng View Live Stream', path: paths.tiktok.viewLivestream },
        { title: 'Tăng Share Live Stream', path: paths.tiktok.shareLivestream },
        { title: 'Tăng PK Live Stream', path: paths.tiktok.pkLivestream },
      ],
    },
  ],
};

const CMS_TIKTOK_SERVICE = {
  title: 'TikTok',
  path: paths.cms.service.tiktok.root,
  children: [
    // follow
    {
      title: 'Tăng Follow',
      path: paths.cms.service.tiktok.follow,
      children: [
        { title: 'Follow Việt', path: paths.cms.service.tiktok.followViet },
        { title: 'Follow Nhanh', path: paths.cms.service.tiktok.followNhanh },
        { title: 'Follow Ngoại', path: paths.cms.service.tiktok.followNgoai },
      ],
    },
    // like
    {
      title: 'Tăng Tim',
      path: paths.cms.service.tiktok.likePost,
      children: [
        { title: 'Tim Việt', path: paths.cms.service.tiktok.likePostViet },
        { title: 'Tim Ngoại', path: paths.cms.service.tiktok.likePostNgoai },
      ],
    },

    // like favorite
    { title: 'Tăng Lưu Video', path: paths.cms.service.tiktok.likeFavorite },

    // view
    { title: 'Tăng View Video', path: paths.cms.service.tiktok.view },

    // share
    { title: 'Tăng Share', path: paths.cms.service.tiktok.share },

    // comment
    { title: 'Tăng Comment', path: paths.cms.service.tiktok.comment },

    // live stream
    {
      title: 'Live Stream',
      path: paths.cms.service.tiktok.livestream,
      children: [
        { title: 'Tăng Tim Live Stream', path: paths.cms.service.tiktok.likeLivestream },
        { title: 'Tăng View Live Stream', path: paths.cms.service.tiktok.viewLivestream },
        { title: 'Tăng Share Live Stream', path: paths.cms.service.tiktok.shareLivestream },
        { title: 'Tăng PK Live Stream', path: paths.cms.service.tiktok.pkLivestream },
      ],
    },
  ],
};

const INSTAGRAM_SERVICE = {
  title: 'Instagram',
  path: paths.instagram.root,
  icon: ICONS.instagram,
  children: [
    // follow
    {
      title: 'Tăng Follow',
      path: paths.instagram.follow,
    },

    // Like
    {
      title: 'Tăng Like',
      path: paths.instagram.like,
    },

    // View
    {
      title: 'Tăng View',
      path: paths.instagram.view,
    },
  ],
};

const CMS_INSTAGRAM_SERVICE = {
  title: 'Instagram',
  path: paths.cms.service.instagram.root,
  children: [
    { title: 'Tăng Follow', path: paths.cms.service.instagram.follow },
    { title: 'Tăng Like', path: paths.cms.service.instagram.like },
    { title: 'Tăng View', path: paths.cms.service.instagram.view },
  ],
};

const TWITTER_SERVICE = {
  title: 'Twitter',
  path: paths.twitter.root,
  icon: ICONS.twitter,
  children: [
    // follow
    {
      title: 'Tăng Follow',
      path: paths.twitter.follow,
    },

    // Like
    {
      title: 'Tăng Like',
      path: paths.twitter.like,
    },
  ],
};

const CMS_TWITTER_SERVICE = {
  title: 'Twitter',
  path: paths.cms.service.twitter.root,
  children: [
    { title: 'Tăng Follow', path: paths.cms.service.twitter.follow },
    { title: 'Tăng Like', path: paths.cms.service.twitter.like },
  ],
};

const TELEGRAM_SERVICE = {
  title: 'Telegram',
  path: paths.telegram.root,
  icon: ICONS.telegram,
  children: [
    {
      title: 'Tăng Member',
      path: paths.telegram.member,
      children: [
        { title: 'Member Việt', path: paths.telegram.memberViet },
        { title: 'Member Ngoại', path: paths.telegram.memberNgoai },
      ],
    },
  ],
};

const CMS_TELEGRAM_SERVICE = {
  title: 'Telegram',
  path: paths.cms.service.telegram.root,
  children: [
    {
      title: 'Tăng Member',
      path: paths.cms.service.telegram.member,
      children: [
        { title: 'Member Việt', path: paths.cms.service.telegram.memberViet },
        { title: 'Member Ngoại', path: paths.cms.service.telegram.memberNgoai },
      ],
    },
  ],
};

const SHOPEE_SERVICE = {
  title: 'Shopee',
  path: paths.shopee.root,
  icon: ICONS.shopee,
  children: [
    // follow
    {
      title: 'Tăng Follow',
      path: paths.shopee.follow,
    },

    // Like
    {
      title: 'Tăng Like',
      path: paths.shopee.like,
    },
  ],
};

const CMS_SHOPEE_SERVICE = {
  title: 'Shopee',
  path: paths.cms.service.shopee.root,
  children: [
    // follow
    {
      title: 'Tăng Follow',
      path: paths.cms.service.shopee.follow,
    },

    // Like
    {
      title: 'Tăng Like',
      path: paths.cms.service.shopee.like,
    },
  ],
};

const LAZADA_SERVICE = {
  title: 'Lazada',
  path: paths.lazada.root,
  icon: ICONS.lazada,
  children: [
    // follow
    {
      title: 'Tăng Follow',
      path: paths.lazada.follow,
    },

    // Like
    {
      title: 'Tăng Like',
      path: paths.lazada.like,
    },
  ],
};

const CMS_LAZADA_SERVICE = {
  title: 'Lazada',
  path: paths.cms.service.lazada.root,
  children: [
    // follow
    {
      title: 'Tăng Follow',
      path: paths.cms.service.lazada.follow,
    },

    // Like
    {
      title: 'Tăng Like',
      path: paths.cms.service.lazada.like,
    },
  ],
};

const GOOGLE_SERVICE = {
  title: 'Google',
  path: paths.google.root,
  icon: ICONS.google,
  children: [
    // follow
    {
      title: 'Đánh giá Map',
      path: paths.google.reviewMap,
    },
  ],
};

const CMS_GOOGLE_SERVICE = {
  title: 'Google',
  path: paths.cms.service.google.root,
  children: [
    // follow
    {
      title: 'Đánh giá Map',
      path: paths.cms.service.google.reviewMap,
    },
  ],
};

const CMS = [
  {
    subheader: 'Quản lý',
    items: [
      {
        title: 'Quản lý proxy',
        path: paths.cms.proxy,
        icon: ICONS.proxyManagement,
        children: [
          { title: 'Proxy', path: paths.cms.proxy },
          { title: 'Proxy dân cư', path: paths.cms.proxyResidential },
          { title: 'Proxy Token', path: paths.cms.proxyToken },
          { title: 'Proxy Country', path: paths.cms.proxyCountry },
          { title: 'Proxy Region', path: paths.cms.proxyRegion },
          { title: 'Proxy Type', path: paths.cms.proxyType },
        ],
      },
      {
        title: 'Quản lý VPS Proxy',
        path: paths.cms.proxyVPS,
        icon: ICONS.proxyVPSManagement,
        children: [
          { title: 'Danh sách', path: paths.cms.proxyVPS },
          { title: 'Thống kê', path: paths.cms.proxyVPSStatistics },
        ],
      },
      {
        title: 'Quản lý VPS',
        path: paths.cms.vpsType,
        icon: ICONS.vps,
      },
      {
        title: 'Quản lý sản phẩm',
        path: paths.cms.product,
        icon: ICONS.product,
      },
      {
        title: 'Quản lý dịch vụ',
        path: paths.cms.service.services,
        icon: ICONS.service,
        children: [
          { title: 'Danh sách dịch vụ', path: paths.cms.service.services },
          CMS_FACEBOOK_SERVICE,
          CMS_YOUTUBE_SERVICE,
          CMS_TIKTOK_SERVICE,
          CMS_INSTAGRAM_SERVICE,
          CMS_TWITTER_SERVICE,
          CMS_TELEGRAM_SERVICE,
          CMS_SHOPEE_SERVICE,
          CMS_LAZADA_SERVICE,
          CMS_GOOGLE_SERVICE,
        ],
      },
      {
        title: 'Quản lý đơn hàng',
        path: paths.cms.order.orders,
        icon: ICONS.order,
        children: [
          { title: 'Tất cả', path: paths.cms.order.orders },
          { title: 'Proxy', path: paths.cms.order.proxyOrders },
          { title: 'VPS', path: paths.cms.order.vps },
        ],
      },
      {
        title: 'Quản lý giao dịch',
        path: paths.cms.transactionHistory.root,
        icon: ICONS.banking,
        children: [
          { title: 'Lịch sử nạp tiền', path: paths.cms.transactionHistory.recharge },
          { title: 'Lịch sử ngân hàng', path: paths.cms.transactionHistory.banking },
        ],
      },
      {
        title: 'Quản lý tài khoản',
        path: paths.cms.user,
        icon: ICONS.user,
      },
      {
        title: 'Thống kê',
        path: paths.cms.statistics,
        icon: ICONS.statistics,
      },
      {
        title: 'Danh sách cronjob',
        path: paths.cms.cronjob,
        icon: ICONS.cronjob,
      },
    ],
  },
];

const CLIENT = [
  {
    subheader: 'Dashboard',
    items: [
      {
        title: 'Kênh hỗ trợ',
        path: paths.support,
        icon: ICONS.support,
      },
    ],
  },
  {
    subheader: 'Sản phẩm',
    items: [
      {
        title: 'Proxy',
        path: paths.proxy.root,
        icon: ICONS.proxy,
        children: [
          {
            title: 'Data center 1',
            path: paths.proxy.dataCenter1.purchased,
          },
          {
            title: 'Data center 2',
            path: paths.proxy.dataCenter2.purchased,
          },
          {
            title: 'Dân cư',
            path: paths.proxy.population.purchased,
          },
          {
            title: 'Proxy Token',
            path: paths.proxy.proxyToken.purchased,
          },
        ],
      },
      {
        title: 'VPS',
        path: paths.vps.root,
        icon: ICONS.vps,
        children: [
          {
            title: 'Mua VPS',
            path: paths.vps.buy,
          },
          {
            title: 'Đơn hàng VPS',
            path: paths.vps.order,
          },
        ],
      },
      {
        title: 'Tool Nuôi Nick',
        path: paths.mktLogin,
        icon: MKTLoginIcon,
      },
    ],
  },
  {
    subheader: 'Dịch vụ',
    items: [
      FACEBOOK_SERVICE,
      YOUTUBE_SERVICE,
      TIKTOK_SERVICE,
      INSTAGRAM_SERVICE,
      TWITTER_SERVICE,
      TELEGRAM_SERVICE,
      SHOPEE_SERVICE,
      LAZADA_SERVICE,
      GOOGLE_SERVICE,
    ],
  },
  {
    subheader: 'Lịch sử giao dịch',
    items: [
      { title: 'Đơn hàng của tôi', path: paths.order.orders, icon: ICONS.cart },
      { title: 'Lịch sử nạp tiền', path: paths.order.rechargeHistory, icon: ICONS.money },
    ],
  },
];
export function useNavData() {
  const { user } = useAuthContext();

  const [currentCheckCMSPath, setCurrentCheckCMSPath] = useState(true);

  // Theo dõi sự thay đổi của pathname
  useEffect(() => {
    const { pathname } = window.location;
    // Kiểm tra nếu pathname không phải là '/profile'
    if (pathname !== '/profile' && pathname !== '/recharge') {
      const isCMSPath = pathname.split('/')[1] === 'cms';
      setCurrentCheckCMSPath(isCMSPath);
    } else {
      setCurrentCheckCMSPath(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.location.pathname]);

  const data = useMemo(
    () => [
      // CLIENT
      // ----------------------------------------------------------------------
      ...(!currentCheckCMSPath ? CLIENT : []),
      // MANAGEMENT
      // ----------------------------------------------------------------------
      ...((user?.role === ROLE.ADMIN || user?.role === ROLE.SUPER_ADMIN) && currentCheckCMSPath
        ? CMS
        : []),
    ],
    [currentCheckCMSPath, user?.role]
  );

  return data;
}
