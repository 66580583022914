import axios from 'axios';
// config
import { HOST_API } from 'src/config-global';
import { clearStorage, setStorage } from 'src/hooks/use-local-storage';
import { paths } from 'src/routes/paths';

// ----------------------------------------------------------------------
function jwtDecode(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  return JSON.parse(jsonPayload);
}

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.request.use(async (config) => {
  const currentDate = new Date();
  const accessToken = localStorage.getItem('accessToken');
  const refreshToken = localStorage.getItem('refreshToken');
  const device = localStorage.getItem('device');
  const MKTLoginAccessToken = localStorage.getItem('MKTLoginToken');

  if (accessToken && jwtDecode(accessToken).exp * 1000 < currentDate.getTime()) {
    try {
      const instance = axios.create({ baseURL: HOST_API });
      const payload = {
        refresh_token: JSON.parse(refreshToken),
        device: JSON.parse(device),
      };
      const response = await instance.post(endpoints.auth.refreshToken, payload);
      setStorage('accessToken', response.data.access_token);
      setStorage('refreshToken', response.data.refresh_token);
      config.headers.Authorization = `Bearer ${response.data.access_token}`;
    } catch (error) {
      console.log(error);
      clearStorage();
      window.location.href = paths.auth.jwt.login;
    }
  } else {
    config.headers.Authorization = `Bearer ${JSON.parse(accessToken)}`;
  }
  if (MKTLoginAccessToken) {
    config.headers['MKT-Login-Token'] = JSON.parse(MKTLoginAccessToken);
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => {
    const excludedUrls = [
      paths.auth.jwt.login,
      paths.auth.jwt.register,
      paths.auth.jwt.forgotPassword,
      paths.auth.jwt.resetPassword,
      paths.auth.jwt.verifyEmail,
      paths.profile,
    ];
    const currentUrl = excludedUrls.includes(window.location.pathname);
    // if (error.response?.status === 401 && !currentUrl) {
    //   clearStorage();
    //   window.location.href = paths.auth.jwt.login;
    //   return false;
    // }

    // bảo trì
    if (error.response?.status === 500 && currentUrl) {
      clearStorage();
      window.location.href = '/maintenance';
      return Promise.reject();
    }

    return Promise.reject(
      (error.response && error.response.data) || 'Đã xảy ra lỗi, vui lòng thử lại sau!'
    );
  }
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------
const ROOTS = {
  CLIENT: 'api/v1',
  ADMIN: 'api/v1/cms',
};

const FACEBOOK_SERVICE_CLIENT = `${ROOTS.CLIENT}/facebook-services`;
const FACEBOOK_SERVICE_ORDERS_ADMIN = `${ROOTS.ADMIN}/facebook-services/orders`;
const FACEBOOK_SERVICE_ORDERS_CLIENT = `${ROOTS.CLIENT}/facebook-services/orders`;

const YOUTUBE_SERVICE_CLIENT = `${ROOTS.CLIENT}/youtube-services`;
const YOUTUBE_SERVICE_ORDERS_CLIENT = `${ROOTS.CLIENT}/youtube-services/orders`;
const YOUTUBE_SERVICE_ORDERS_ADMIN = `${ROOTS.ADMIN}/youtube-services/orders`;

const TIKTOK_SERVICE_CLIENT = `${ROOTS.CLIENT}/tiktok-services`;
const TIKTOK_SERVICE_ORDERS_CLIENT = `${ROOTS.CLIENT}/tiktok-services/orders`;
const TIKTOK_SERVICE_ORDERS_ADMIN = `${ROOTS.ADMIN}/tiktok-services/orders`;

const INSTAGRAM_SERVICE_CLIENT = `${ROOTS.CLIENT}/instagram-services`;
const INSTAGRAM_SERVICE_ORDERS_CLIENT = `${ROOTS.CLIENT}/instagram-services/orders`;
const INSTAGRAM_SERVICE_ORDERS_ADMIN = `${ROOTS.ADMIN}/instagram-services/orders`;

const TWITTER_SERVICE_CLIENT = `${ROOTS.CLIENT}/twitter-services`;
const TWITTER_SERVICE_ORDERS_CLIENT = `${ROOTS.CLIENT}/twitter-services/orders`;
const TWITTER_SERVICE_ORDERS_ADMIN = `${ROOTS.ADMIN}/twitter-services/orders`;

const TELEGRAM_SERVICE_CLIENT = `${ROOTS.CLIENT}/telegram-services`;
const TELEGRAM_SERVICE_ORDERS_CLIENT = `${ROOTS.CLIENT}/telegram-services/orders`;
const TELEGRAM_SERVICE_ORDERS_ADMIN = `${ROOTS.ADMIN}/telegram-services/orders`;

const SHOPEE_SERVICE_CLIENT = `${ROOTS.CLIENT}/shopee-services`;
const SHOPEE_SERVICE_ORDERS_CLIENT = `${ROOTS.CLIENT}/shopee-services/orders`;
const SHOPEE_SERVICE_ORDERS_ADMIN = `${ROOTS.ADMIN}/shopee-services/orders`;

const LAZADA_SERVICE_CLIENT = `${ROOTS.CLIENT}/lazada-services`;
const LAZADA_SERVICE_ORDERS_CLIENT = `${ROOTS.CLIENT}/lazada-services/orders`;
const LAZADA_SERVICE_ORDERS_ADMIN = `${ROOTS.ADMIN}/lazada-services/orders`;

const GOOGLE_SERVICE_CLIENT = `${ROOTS.CLIENT}/google-services`;
const GOOGLE_SERVICE_ORDERS_CLIENT = `${ROOTS.CLIENT}/google-services/orders`;
const GOOGLE_SERVICE_ORDERS_ADMIN = `${ROOTS.ADMIN}/google-services/orders`;

export const endpoints = {
  auth: {
    me: `${ROOTS.CLIENT}/auth/profile`,
    login: `${ROOTS.CLIENT}/auth/login`,
    refreshToken: `${ROOTS.CLIENT}/auth/refresh-tokens`,
    loginWithGoogle: `${ROOTS.CLIENT}/auth/login-google`,
    loginWithMKTLogin: `${ROOTS.CLIENT}/auth/login-mkt-login`,
    register: `${ROOTS.CLIENT}/auth/register`,
    forgotPassword: `${ROOTS.CLIENT}/auth/forgot-password`,
    resetPassword: `${ROOTS.CLIENT}/auth/reset-password`,
    verifyEmail: `${ROOTS.CLIENT}/auth/verify-email`,
    changePassword: `${ROOTS.CLIENT}/auth/change-password`,
    logout: `${ROOTS.CLIENT}/auth/logout`,
  },
  proxy: {
    list1: `${ROOTS.CLIENT}/proxies/data-center-1/list`,
    list2: `${ROOTS.CLIENT}/proxies/data-center-2/list`,
    residential: `${ROOTS.CLIENT}/proxies/proxy-res/list`,
    proxyToken: `${ROOTS.CLIENT}/proxies/proxy-token/list`,
    getArea1: `${ROOTS.CLIENT}/proxies/data-center-1/get-area`,
    getArea2: `${ROOTS.CLIENT}/proxies/data-center-2/get-area`,
    getRes: `${ROOTS.CLIENT}/proxies/proxy-res/get-area`,
    getAreaProxyToken: `${ROOTS.CLIENT}/proxies/proxy-token/get-area`,
    buy1: `${ROOTS.CLIENT}/proxies/data-center-1/buy`,
    buy2: `${ROOTS.CLIENT}/proxies/data-center-2/buy`,
    buyRes: `${ROOTS.CLIENT}/proxies/proxy-res/buy`,
    buyProxyToken: `${ROOTS.CLIENT}/proxies/proxy-token/buy`,
    deleteById: (proxyId) => `${ROOTS.CLIENT}/proxies/${proxyId}`,
    deleteMultiple: `${ROOTS.CLIENT}/proxies/delete-multiple`,
    renew1: `${ROOTS.CLIENT}/proxies/data-center-1/renew`,
    renew2: `${ROOTS.CLIENT}/proxies/data-center-2/renew`,
    renewRes: `${ROOTS.CLIENT}/proxies/proxy-res/renew`,
    transfer: `${ROOTS.CLIENT}/proxies/transfer`,
    turnOn: (proxyId) => `${ROOTS.CLIENT}/proxies/turn-on-auto-renew/${proxyId}`,
    turnOff: (proxyId) => `${ROOTS.CLIENT}/proxies/turn-off-auto-renew/${proxyId}`,
  },
  proxyCountry: {
    list: `${ROOTS.CLIENT}/proxy-countries/list`,
  },
  proxyType: {
    list: `${ROOTS.CLIENT}/proxy-types/list`,
  },
  order: {
    orders: `${ROOTS.CLIENT}/orders`,
  },
  transactionHistory: {
    recharge: `${ROOTS.CLIENT}/transaction-histories`,
  },
  vpsType: {
    list: `${ROOTS.CLIENT}/vps-types/list`,
  },
  vps: {
    buyNormal: `${ROOTS.CLIENT}/vps/normal/buy`,
    buyCustom: `${ROOTS.CLIENT}/vps/custom/buy`,
    order: `${ROOTS.CLIENT}/vps/purchased`,
    renew: `${ROOTS.CLIENT}/vps/renew`,
    rebootById: (id) => `${ROOTS.CLIENT}/vps/action/${id}`,
  },

  cms: {
    proxyCountry: {
      list: `${ROOTS.ADMIN}/proxy-countries`,
      deleteMultiple: `${ROOTS.ADMIN}/proxy-countries/delete-multiple`,
      deleteById: (proxyCountryId) => `${ROOTS.ADMIN}/proxy-countries/${proxyCountryId}`,
      updateById: (proxyCountryId) => `${ROOTS.ADMIN}/proxy-countries/${proxyCountryId}`,
    },
    proxyRegion: {
      list: `${ROOTS.ADMIN}/proxy-regions`,
      deleteMultiple: `${ROOTS.ADMIN}/proxy-regions/delete-multiple`,
      deleteById: (proxyRegionId) => `${ROOTS.ADMIN}/proxy-regions/${proxyRegionId}`,
      updateById: (proxyRegionId) => `${ROOTS.ADMIN}/proxy-regions/${proxyRegionId}`,
    },
    proxyType: {
      list: `${ROOTS.ADMIN}/proxy-types`,
      deleteMultiple: `${ROOTS.ADMIN}/proxy-types/delete-multiple`,
      deleteById: (proxyTypeId) => `${ROOTS.ADMIN}/proxy-types/${proxyTypeId}`,
      updateById: (proxyTypeId) => `${ROOTS.ADMIN}/proxy-types/${proxyTypeId}`,
    },
    proxy: {
      list: `${ROOTS.ADMIN}/proxies`,
      deleteMultiple: `${ROOTS.ADMIN}/proxies/delete-multiple`,
      createMultiple: `${ROOTS.ADMIN}/proxies/create-multiple`,
      deleteById: (proxyId) => `${ROOTS.ADMIN}/proxies/${proxyId}`,
      updateById: (proxyId) => `${ROOTS.ADMIN}/proxies/${proxyId}`,
      turnOff: `${ROOTS.ADMIN}/proxies/turn-off-auto-renew`,
    },
    proxyResidential: {
      list: '/api/v1/cms/proxies/proxy-res',
    },
    proxyToken: {
      list: '/api/v1/cms/proxies/proxy-token',
      country: '/api/v1/proxies/proxy-token/get-area',
    },
    proxyVPS: {
      list: `${ROOTS.ADMIN}/proxy-vps`,
      statistics: `${ROOTS.ADMIN}/proxy-vps/statistics`,
      deleteMultiple: `${ROOTS.ADMIN}/proxy-vps/delete-multiple`,
      createMultiple: `${ROOTS.ADMIN}/proxy-vps/create-multiple`,
      deleteById: (proxyVPSId) => `${ROOTS.ADMIN}/proxy-vps/${proxyVPSId}`,
      updateById: (proxyVPSId) => `${ROOTS.ADMIN}/proxy-vps/${proxyVPSId}`,
    },
    vpsType: {
      list: `${ROOTS.ADMIN}/vps-types`,
      updateById: (id) => `${ROOTS.ADMIN}/vps-types/${id}`,
    },
    product: {
      list: `${ROOTS.ADMIN}/products`,
      listParent: `${ROOTS.ADMIN}/products/list-parent`,
      deleteMultiple: `${ROOTS.ADMIN}/products/delete-multiple`,
      deleteById: (productId) => `${ROOTS.ADMIN}/products/${productId}`,
      updateById: (productId) => `${ROOTS.ADMIN}/products/${productId}`,
    },
    user: {
      list: `${ROOTS.ADMIN}/users`,
      deleteById: (userId) => `${ROOTS.ADMIN}/users/${userId}`,
      updateBalance: (userId) => `${ROOTS.ADMIN}/users/update-balance/${userId}`,
      updateRole: (userId) => `${ROOTS.ADMIN}/users/update-role/${userId}`,
    },
    transactionHistory: {
      list: `${ROOTS.ADMIN}/transaction-histories`,
    },
    bankingHistory: {
      list: `${ROOTS.ADMIN}/banking-transactions`,
    },
    order: {
      orders: `${ROOTS.ADMIN}/orders`,
      proxyOrders: `${ROOTS.ADMIN}/proxy-orders`,
      vpsOrders: `${ROOTS.ADMIN}/vps-orders`,
      updateOrderStatus: (orderId) => `${ROOTS.ADMIN}/order-services/status/${orderId}`,

      facebookServices: {
        likePost1: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/like-post-1`,
        likePost2: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/like-post-2`,
        likePost3: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/like-post-3`,

        likePage1: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/like-page-1`,
        likePage2: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/like-page-2`,
        likePage3: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/like-page-3`,
        likePage4: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/like-page-4`,

        reviewPage: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/review-page`,

        checkInPage: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/checkin-page`,

        friend: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/friend`,

        liveStream1: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/live-1`,
        liveStream2: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/live-2`,
        liveStream3: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/live-3`,

        comment1: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/comment-1`,
        comment2: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/comment-2`,
        comment3: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/comment-3`,

        likeComment: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/like-comment`,

        sharePostGroup: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/share-post-group`,

        sharePostProfile: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/share-post-profile`,

        memberGroup1: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/member-group-1`,
        memberGroup2: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/member-group-2`,

        follow1: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/follow-1`,
        follow2: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/follow-2`,
        follow3: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/follow-3`,

        vipLike1: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/vip-like-1`,
        vipLike2: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/vip-like-2`,

        vipComment: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/vip-comment`,

        vipLikeGroup: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/vip-like-group`,

        vipLive: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/vip-live`,

        view600k: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/view-600k`,
        normalView: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/view-3s`,
        vipView: `${FACEBOOK_SERVICE_ORDERS_ADMIN}/view-vip`,
      },

      youtubeServices: {
        subscribeViet: `${YOUTUBE_SERVICE_ORDERS_ADMIN}/subscribe-viet`,
        subscribeNgoai: `${YOUTUBE_SERVICE_ORDERS_ADMIN}/subscribe-ngoai`,

        likeVideo: `${YOUTUBE_SERVICE_ORDERS_ADMIN}/like-video`,
        dislikeVideo: `${YOUTUBE_SERVICE_ORDERS_ADMIN}/dislike-video`,

        viewNormal: `${YOUTUBE_SERVICE_ORDERS_ADMIN}/view-normal`,
        viewSocial: `${YOUTUBE_SERVICE_ORDERS_ADMIN}/view-social`,
        viewNativeADS30: `${YOUTUBE_SERVICE_ORDERS_ADMIN}/view-native-ads-30k`,
        viewNativeADS500: `${YOUTUBE_SERVICE_ORDERS_ADMIN}/view-native-ads-500k`,

        watchTime: `${YOUTUBE_SERVICE_ORDERS_ADMIN}/watch-time`,

        likeComment: `${YOUTUBE_SERVICE_ORDERS_ADMIN}/like-comment`,

        commentViet: `${YOUTUBE_SERVICE_ORDERS_ADMIN}/comment-viet`,
        commentNgoai: `${YOUTUBE_SERVICE_ORDERS_ADMIN}/comment-ngoai`,
      },

      tiktokServices: {
        followViet: `${TIKTOK_SERVICE_ORDERS_ADMIN}/follow-viet`,
        followNhanh: `${TIKTOK_SERVICE_ORDERS_ADMIN}/follow-nhanh`,
        followNgoai: `${TIKTOK_SERVICE_ORDERS_ADMIN}/follow-ngoai`,

        likePostViet: `${TIKTOK_SERVICE_ORDERS_ADMIN}/like-viet`,
        likePostNgoai: `${TIKTOK_SERVICE_ORDERS_ADMIN}/like-ngoai`,

        likeFavorite: `${TIKTOK_SERVICE_ORDERS_ADMIN}/like-favorite`,

        view: `${TIKTOK_SERVICE_ORDERS_ADMIN}/view`,

        share: `${TIKTOK_SERVICE_ORDERS_ADMIN}/share`,

        comment: `${TIKTOK_SERVICE_ORDERS_ADMIN}/comment`,

        likeLiveStream: `${TIKTOK_SERVICE_ORDERS_ADMIN}/like-livestream`,
        shareLiveStream: `${TIKTOK_SERVICE_ORDERS_ADMIN}/share-livestream`,
        pkLiveStream: `${TIKTOK_SERVICE_ORDERS_ADMIN}/pk-livestream`,
        viewLiveStream: `${TIKTOK_SERVICE_ORDERS_ADMIN}/view-livestream`,
      },

      instagramServices: {
        follow: `${INSTAGRAM_SERVICE_ORDERS_ADMIN}/follow`,
        like: `${INSTAGRAM_SERVICE_ORDERS_ADMIN}/like`,
        view: `${INSTAGRAM_SERVICE_ORDERS_ADMIN}/view`,
      },

      twitterServices: {
        follow: `${TWITTER_SERVICE_ORDERS_ADMIN}/follow`,
        like: `${TWITTER_SERVICE_ORDERS_ADMIN}/like`,
      },

      telegramServices: {
        memberViet: `${TELEGRAM_SERVICE_ORDERS_ADMIN}/member-viet`,
        memberNgoai: `${TELEGRAM_SERVICE_ORDERS_ADMIN}/member-ngoai`,
      },

      shopeeServices: {
        follow: `${SHOPEE_SERVICE_ORDERS_ADMIN}/follow`,
        like: `${SHOPEE_SERVICE_ORDERS_ADMIN}/like`,
      },

      lazadaServices: {
        follow: `${LAZADA_SERVICE_ORDERS_ADMIN}/follow`,
        like: `${LAZADA_SERVICE_ORDERS_ADMIN}/like`,
      },

      googleServices: {
        reviewMap: `${GOOGLE_SERVICE_ORDERS_ADMIN}/review-map`,
      },
    },
    serviceType: {
      list: `${ROOTS.ADMIN}/service-types`,
      updateById: (id) => `${ROOTS.ADMIN}/service-types/${id}`,
    },
  },
  serviceType: {
    list: `${ROOTS.CLIENT}/service-types`,
  },
  maintainById: (id) => `${ROOTS.CLIENT}/order-services/maintenance/${id}`,
  facebookServices: {
    getId: `${FACEBOOK_SERVICE_CLIENT}/get-id`,

    likePost1: `${FACEBOOK_SERVICE_CLIENT}/buff-like-post-1`,
    likePost2: `${FACEBOOK_SERVICE_CLIENT}/buff-like-post-2`,
    likePost3: `${FACEBOOK_SERVICE_CLIENT}/buff-like-post-3`,

    likePage1: `${FACEBOOK_SERVICE_CLIENT}/buff-like-page-1`,
    likePage2: `${FACEBOOK_SERVICE_CLIENT}/buff-like-page-2`,
    likePage3: `${FACEBOOK_SERVICE_CLIENT}/buff-like-page-3`,
    likePage4: `${FACEBOOK_SERVICE_CLIENT}/buff-like-page-4`,

    reviewPage: `${FACEBOOK_SERVICE_CLIENT}/buff-review-page`,

    checkInPage: `${FACEBOOK_SERVICE_CLIENT}/buff-checkin-page`,

    friend: `${FACEBOOK_SERVICE_CLIENT}/buff-friend`,

    follow1: `${FACEBOOK_SERVICE_CLIENT}/buff-follow-1`,
    follow2: `${FACEBOOK_SERVICE_CLIENT}/buff-follow-2`,
    follow3: `${FACEBOOK_SERVICE_CLIENT}/buff-follow-3`,

    comment1: `${FACEBOOK_SERVICE_CLIENT}/buff-comment-1`,
    comment2: `${FACEBOOK_SERVICE_CLIENT}/buff-comment-2`,
    comment3: `${FACEBOOK_SERVICE_CLIENT}/buff-comment-3`,
    likeComment: `${FACEBOOK_SERVICE_CLIENT}/buff-like-comment`,

    liveStream1: `${FACEBOOK_SERVICE_CLIENT}/buff-live-1`,
    liveStream2: `${FACEBOOK_SERVICE_CLIENT}/buff-live-2`,
    liveStream3: `${FACEBOOK_SERVICE_CLIENT}/buff-live-3`,
    vipLive: `${FACEBOOK_SERVICE_CLIENT}/buff-vip-live`,

    sharePostGroup: `${FACEBOOK_SERVICE_CLIENT}/buff-share-post-group`,

    sharePostProfile: `${FACEBOOK_SERVICE_CLIENT}/buff-share-post-profile`,

    memberGroup1: `${FACEBOOK_SERVICE_CLIENT}/buff-member-group-1`,
    memberGroup2: `${FACEBOOK_SERVICE_CLIENT}/buff-member-group-2`,

    vipLike1: `${FACEBOOK_SERVICE_CLIENT}/buff-vip-like-1`,
    vipLike2: `${FACEBOOK_SERVICE_CLIENT}/buff-vip-like-2`,

    vipLikeGroup: `${FACEBOOK_SERVICE_CLIENT}/buff-vip-like-group`,

    vipComment: `${FACEBOOK_SERVICE_CLIENT}/buff-vip-comment`,

    view600k: `${FACEBOOK_SERVICE_CLIENT}/buff-view-600k`,
    normalView: `${FACEBOOK_SERVICE_CLIENT}/buff-view-3s`,
    vipView: `${FACEBOOK_SERVICE_CLIENT}/buff-view-vip`,

    order: {
      likePost1: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/like-post-1`,
      likePost2: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/like-post-2`,
      likePost3: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/like-post-3`,

      likePage1: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/like-page-1`,
      likePage2: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/like-page-2`,
      likePage3: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/like-page-3`,
      likePage4: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/like-page-4`,

      liveStream1: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/live-1`,
      liveStream2: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/live-2`,
      liveStream3: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/live-3`,

      comment1: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/comment-1`,
      comment2: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/comment-2`,
      comment3: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/comment-3`,

      likeComment: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/like-comment`,

      reviewPage: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/review-page`,

      friend: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/friend`,

      checkInPage: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/checkin-page`,

      sharePostGroup: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/share-post-group`,

      sharePostProfile: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/share-post-profile`,

      memberGroup1: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/member-group-1`,
      memberGroup2: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/member-group-2`,

      follow1: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/follow-1`,
      follow2: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/follow-2`,
      follow3: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/follow-3`,

      vipLike1: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/vip-like-1`,
      vipLike2: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/vip-like-2`,

      vipComment: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/vip-comment`,

      vipLikeGroup: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/vip-like-group`,

      vipLive: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/vip-live`,

      view600k: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/view-600k`,
      normalView: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/view-3s`,
      vipView: `${FACEBOOK_SERVICE_ORDERS_CLIENT}/view-vip`,
    },
  },

  youtubeServices: {
    subscribeViet: `${YOUTUBE_SERVICE_CLIENT}/buff-subscribe-viet`,
    subscribeNgoai: `${YOUTUBE_SERVICE_CLIENT}/buff-subscribe-ngoai`,

    likeVideo: `${YOUTUBE_SERVICE_CLIENT}/buff-like-video`,
    dislikeVideo: `${YOUTUBE_SERVICE_CLIENT}/buff-dislike-video`,

    viewNormal: `${YOUTUBE_SERVICE_CLIENT}/buff-view-normal`,
    viewSocial: `${YOUTUBE_SERVICE_CLIENT}/buff-view-social`,
    viewNativeADS30: `${YOUTUBE_SERVICE_CLIENT}/buff-view-native-ads-30k`,
    viewNativeADS500: `${YOUTUBE_SERVICE_CLIENT}/buff-view-native-ads-500k`,

    watchTime: `${YOUTUBE_SERVICE_CLIENT}/buff-watch-time`,

    likeComment: `${YOUTUBE_SERVICE_CLIENT}/buff-like-comment`,

    commentViet: `${YOUTUBE_SERVICE_CLIENT}/buff-comment-viet`,
    commentNgoai: `${YOUTUBE_SERVICE_CLIENT}/buff-comment-ngoai`,

    order: {
      subscribeViet: `${YOUTUBE_SERVICE_ORDERS_CLIENT}/subscribe-viet`,
      subscribeNgoai: `${YOUTUBE_SERVICE_ORDERS_CLIENT}/subscribe-ngoai`,

      likeVideo: `${YOUTUBE_SERVICE_ORDERS_CLIENT}/like-video`,
      dislikeVideo: `${YOUTUBE_SERVICE_ORDERS_CLIENT}/dislike-video`,

      viewNormal: `${YOUTUBE_SERVICE_ORDERS_CLIENT}/view-normal`,
      viewSocial: `${YOUTUBE_SERVICE_ORDERS_CLIENT}/view-social`,
      viewNativeADS30: `${YOUTUBE_SERVICE_ORDERS_CLIENT}/view-native-ads-30k`,
      viewNativeADS500: `${YOUTUBE_SERVICE_ORDERS_CLIENT}/view-native-ads-500k`,

      watchTime: `${YOUTUBE_SERVICE_ORDERS_CLIENT}/watch-time`,

      likeComment: `${YOUTUBE_SERVICE_ORDERS_CLIENT}/like-comment`,

      commentViet: `${YOUTUBE_SERVICE_ORDERS_CLIENT}/comment-viet`,
      commentNgoai: `${YOUTUBE_SERVICE_ORDERS_CLIENT}/comment-ngoai`,
    },
  },

  tiktokServices: {
    followViet: `${TIKTOK_SERVICE_CLIENT}/buff-follow-viet`,
    followNhanh: `${TIKTOK_SERVICE_CLIENT}/buff-follow-nhanh`,
    followNgoai: `${TIKTOK_SERVICE_CLIENT}/buff-follow-ngoai`,

    likePostViet: `${TIKTOK_SERVICE_CLIENT}/buff-like-viet`,
    likePostNgoai: `${TIKTOK_SERVICE_CLIENT}/buff-like-ngoai`,

    likeFavorite: `${TIKTOK_SERVICE_CLIENT}/buff-like-favorite`,

    view: `${TIKTOK_SERVICE_CLIENT}/buff-view`,

    share: `${TIKTOK_SERVICE_CLIENT}/buff-share`,

    comment: `${TIKTOK_SERVICE_CLIENT}/buff-comment`,

    likeLiveStream: `${TIKTOK_SERVICE_CLIENT}/buff-like-livestream`,
    shareLiveStream: `${TIKTOK_SERVICE_CLIENT}/buff-share-livestream`,
    pkLiveStream: `${TIKTOK_SERVICE_CLIENT}/buff-pk-livestream`,
    viewLiveStream: `${TIKTOK_SERVICE_CLIENT}/buff-view-livestream`,

    order: {
      followViet: `${TIKTOK_SERVICE_ORDERS_CLIENT}/follow-viet`,
      followNhanh: `${TIKTOK_SERVICE_ORDERS_CLIENT}/follow-nhanh`,
      followNgoai: `${TIKTOK_SERVICE_ORDERS_CLIENT}/follow-ngoai`,

      likePostViet: `${TIKTOK_SERVICE_ORDERS_CLIENT}/like-viet`,
      likePostNgoai: `${TIKTOK_SERVICE_ORDERS_CLIENT}/like-ngoai`,

      likeFavorite: `${TIKTOK_SERVICE_ORDERS_CLIENT}/like-favorite`,

      view: `${TIKTOK_SERVICE_ORDERS_CLIENT}/view`,

      share: `${TIKTOK_SERVICE_ORDERS_CLIENT}/share`,

      comment: `${TIKTOK_SERVICE_ORDERS_CLIENT}/comment`,

      likeLiveStream: `${TIKTOK_SERVICE_ORDERS_CLIENT}/like-livestream`,
      shareLiveStream: `${TIKTOK_SERVICE_ORDERS_CLIENT}/share-livestream`,
      pkLiveStream: `${TIKTOK_SERVICE_ORDERS_CLIENT}/pk-livestream`,
      viewLiveStream: `${TIKTOK_SERVICE_ORDERS_CLIENT}/view-livestream`,
    },
  },

  instagramServices: {
    follow: `${INSTAGRAM_SERVICE_CLIENT}/buff-follow`,
    like: `${INSTAGRAM_SERVICE_CLIENT}/buff-like`,
    view: `${INSTAGRAM_SERVICE_CLIENT}/buff-view`,

    order: {
      follow: `${INSTAGRAM_SERVICE_ORDERS_CLIENT}/follow`,
      like: `${INSTAGRAM_SERVICE_ORDERS_CLIENT}/like`,
      view: `${INSTAGRAM_SERVICE_ORDERS_CLIENT}/view`,
    },
  },

  twitterServices: {
    follow: `${TWITTER_SERVICE_CLIENT}/buff-follow`,
    like: `${TWITTER_SERVICE_CLIENT}/buff-like`,

    order: {
      follow: `${TWITTER_SERVICE_ORDERS_CLIENT}/follow`,
      like: `${TWITTER_SERVICE_ORDERS_CLIENT}/like`,
    },
  },

  telegramServices: {
    memberViet: `${TELEGRAM_SERVICE_CLIENT}/buff-member-viet`,
    memberNgoai: `${TELEGRAM_SERVICE_CLIENT}/buff-member-ngoai`,

    order: {
      memberViet: `${TELEGRAM_SERVICE_ORDERS_CLIENT}/member-viet`,
      memberNgoai: `${TELEGRAM_SERVICE_ORDERS_CLIENT}/member-ngoai`,
    },
  },

  shopeeServices: {
    follow: `${SHOPEE_SERVICE_CLIENT}/buff-follow`,
    like: `${SHOPEE_SERVICE_CLIENT}/buff-like`,

    order: {
      follow: `${SHOPEE_SERVICE_ORDERS_CLIENT}/follow`,
      like: `${SHOPEE_SERVICE_ORDERS_CLIENT}/like`,
    },
  },

  lazadaServices: {
    follow: `${LAZADA_SERVICE_CLIENT}/buff-follow`,
    like: `${LAZADA_SERVICE_CLIENT}/buff-like`,

    order: {
      follow: `${LAZADA_SERVICE_ORDERS_CLIENT}/follow`,
      like: `${LAZADA_SERVICE_ORDERS_CLIENT}/like`,
    },
  },

  googleServices: {
    reviewMap: `${GOOGLE_SERVICE_CLIENT}/buff-review-map`,

    order: {
      reviewMap: `${GOOGLE_SERVICE_ORDERS_CLIENT}/review-map`,
    },
  },
  statistic: {
    summary: `${ROOTS.ADMIN}/statistics/summary`,
    chart: `${ROOTS.ADMIN}/statistics/chart`,
  },
  cronjob: {
    list: `${ROOTS.ADMIN}/cron-job/list`,
  },
};
