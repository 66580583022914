// ----------------------------------------------------------------------

const ROOTS = {
  CMS: '/cms',
  CMS_SERVICE: '/cms/services',
  CMS_FACEBOOK_SERVICE: '/cms/services/facebook',
  FACEBOOK: `/facebook`,
  YOUTUBE: `/youtube`,
  CMS_YOUTUBE_SERVICE: '/cms/services/youtube',
  TIKTOK: '/tiktok',
  CMS_TIKTOK_SERVICE: '/cms/services/tiktok',
  INSTAGRAM: '/instagram',
  CMS_INSTAGRAM_SERVICE: '/cms/services/instagram',
  TWITTER: '/twitter',
  CMS_TWITTER_SERVICE: '/cms/services/twitter',
  TELEGRAM: '/telegram',
  CMS_TELEGRAM_SERVICE: '/cms/services/telegram',
  SHOPEE: '/shopee',
  CMS_SHOPEE_SERVICE: '/cms/services/shopee',
  LAZADA: '/lazada',
  CMS_LAZADA_SERVICE: '/cms/services/lazada',
  GOOGLE: '/google',
  CMS_GOOGLE_SERVICE: '/cms/services/google',
};

// ----------------------------------------------------------------------

export const paths = {
  minimalUI: 'https://mui.com/store/items/minimal-dashboard/',
  // AUTH
  auth: {
    jwt: {
      login: `/login`,
      register: `/register`,
      forgotPassword: `/forgot-password`,
      resetPassword: `/reset-password`,
      verifyEmail: `/verify-email`,
    },
  },
  // DASHBOARD
  dashboard: {
    root: '',
  },

  // SUPPORT
  support: '/support',

  // MKTLogin
  mktLogin: '/mkt-login',

  // PROXY
  proxy: {
    root: 'proxy',
    dataCenter1: {
      purchased: '/proxy/data-center-1',
      buy: '/proxy/data-center-1/buy',
    },
    dataCenter2: {
      purchased: '/proxy/data-center-2',
      buy: '/proxy/data-center-2/buy',
    },
    population: {
      purchased: '/proxy/population',
      buy: '/proxy/population/buy',
    },
    proxyToken: {
      purchased: '/proxy/proxy-token',
      buy: '/proxy/proxy-token/buy',
    },
  },
  proxyCountry: `/proxy-country`,

  // VPS
  vps: {
    root: 'vps',
    buy: '/vps/buy',
    order: '/vps/orders',
  },

  profile: '/profile',

  order: {
    orders: `/orders`,
    rechargeHistory: `/recharge-history`,
  },

  recharge: '/recharge',

  cms: {
    proxy: `${ROOTS.CMS}/proxy`,
    proxyResidential: `${ROOTS.CMS}/proxy/residential`,
    proxyToken: `${ROOTS.CMS}/proxy/token`,
    proxyCountry: `${ROOTS.CMS}/proxy/country`,
    proxyRegion: `${ROOTS.CMS}/proxy/region`,
    proxyType: `${ROOTS.CMS}/proxy/type`,
    product: `${ROOTS.CMS}/product`,
    proxyVPS: `${ROOTS.CMS}/proxy-vps`,
    vpsType: `${ROOTS.CMS}/vps-type`,
    proxyVPSStatistics: `${ROOTS.CMS}/proxy-vps/statistics`,
    user: `${ROOTS.CMS}/users`,
    transactionHistory: {
      root: `${ROOTS.CMS}/transaction-history`,
      recharge: `${ROOTS.CMS}/transaction-history/recharge`,
      banking: `${ROOTS.CMS}/transaction-history/banking`,
    },

    order: {
      orders: `${ROOTS.CMS}/orders`,
      proxyOrders: `${ROOTS.CMS}/orders/proxy`,
      vps: `${ROOTS.CMS}/orders/vps`,
    },

    statistics: `${ROOTS.CMS}/statistics`,

    cronjob: `${ROOTS.CMS}/cron-job`,

    service: {
      services: `${ROOTS.CMS_SERVICE}`,

      facebook: {
        root: `${ROOTS.CMS_FACEBOOK_SERVICE}`,
        likePost: `${ROOTS.CMS_FACEBOOK_SERVICE}/like-post`,
        likePost1: `${ROOTS.CMS_FACEBOOK_SERVICE}/like-post/server1`,
        likePost2: `${ROOTS.CMS_FACEBOOK_SERVICE}/like-post/server2`,
        likePost3: `${ROOTS.CMS_FACEBOOK_SERVICE}/like-post/server3`,

        likePage: `${ROOTS.CMS_FACEBOOK_SERVICE}/like-page`,
        likePage1: `${ROOTS.CMS_FACEBOOK_SERVICE}/like-page/server1`,
        likePage2: `${ROOTS.CMS_FACEBOOK_SERVICE}/like-page/server2`,
        likePage3: `${ROOTS.CMS_FACEBOOK_SERVICE}/like-page/server3`,
        likePage4: `${ROOTS.CMS_FACEBOOK_SERVICE}/like-page/server4`,

        follow: `${ROOTS.CMS_FACEBOOK_SERVICE}/follow`,
        follow1: `${ROOTS.CMS_FACEBOOK_SERVICE}/follow/server1`,
        follow2: `${ROOTS.CMS_FACEBOOK_SERVICE}/follow/server2`,
        follow3: `${ROOTS.CMS_FACEBOOK_SERVICE}/follow/server3`,

        friend: `${ROOTS.CMS_FACEBOOK_SERVICE}/friend`,

        comment: `${ROOTS.CMS_FACEBOOK_SERVICE}/comment`,
        comment1: `${ROOTS.CMS_FACEBOOK_SERVICE}/comment/server1`,
        comment2: `${ROOTS.CMS_FACEBOOK_SERVICE}/comment/server2`,
        comment3: `${ROOTS.CMS_FACEBOOK_SERVICE}/comment/server3`,

        likeComment: `${ROOTS.CMS_FACEBOOK_SERVICE}/like-comment`,

        liveStream: `${ROOTS.CMS_FACEBOOK_SERVICE}/optic-live`,
        liveStream1: `${ROOTS.CMS_FACEBOOK_SERVICE}/optic-live/server1`,
        liveStream2: `${ROOTS.CMS_FACEBOOK_SERVICE}/optic-live/server2`,
        liveStream3: `${ROOTS.CMS_FACEBOOK_SERVICE}/optic-live/server3`,

        reviewPage: `${ROOTS.CMS_FACEBOOK_SERVICE}/review-page`,

        checkInPage: `${ROOTS.CMS_FACEBOOK_SERVICE}/check-in-page`,

        vipLike: `${ROOTS.CMS_FACEBOOK_SERVICE}/vip-like`,
        vipLike1: `${ROOTS.CMS_FACEBOOK_SERVICE}/vip-like/server1`,
        vipLike2: `${ROOTS.CMS_FACEBOOK_SERVICE}/vip-like/server2`,

        vipLikeGroup: `${ROOTS.CMS_FACEBOOK_SERVICE}/vip-like-group`,

        vipComment: `${ROOTS.CMS_FACEBOOK_SERVICE}/vip-comment`,

        vipLive: `${ROOTS.CMS_FACEBOOK_SERVICE}/vip-live`,

        view: `${ROOTS.CMS_FACEBOOK_SERVICE}/view`,
        vipView: `${ROOTS.CMS_FACEBOOK_SERVICE}/view/vip`,
        normalView: `${ROOTS.CMS_FACEBOOK_SERVICE}/view/normal`,
        view600k: `${ROOTS.CMS_FACEBOOK_SERVICE}/view/600k`,

        sharePostGroup: `${ROOTS.CMS_FACEBOOK_SERVICE}/share-post-group`,

        sharePostWall: `${ROOTS.CMS_FACEBOOK_SERVICE}/share-post-wall`,

        memberGroup: `${ROOTS.CMS_FACEBOOK_SERVICE}/member-group`,
        memberGroup1: `${ROOTS.CMS_FACEBOOK_SERVICE}/member-group/server1`,
        memberGroup2: `${ROOTS.CMS_FACEBOOK_SERVICE}/member-group/server2`,
      },

      youtube: {
        root: `${ROOTS.CMS_YOUTUBE_SERVICE}`,
        subscribe: `${ROOTS.CMS_YOUTUBE_SERVICE}/subscribe`,
        subscribeViet: `${ROOTS.CMS_YOUTUBE_SERVICE}/subscribe/viet`,
        subscribeNgoai: `${ROOTS.CMS_YOUTUBE_SERVICE}/subscribe/ngoai`,
        likeVideo: `${ROOTS.CMS_YOUTUBE_SERVICE}/like-video`,
        dislikeVideo: `${ROOTS.CMS_YOUTUBE_SERVICE}/dislike-video`,
        likeComment: `${ROOTS.CMS_YOUTUBE_SERVICE}/like-comment`,
        view: `${ROOTS.CMS_YOUTUBE_SERVICE}/view`,
        viewNormal: `${ROOTS.CMS_YOUTUBE_SERVICE}/view/normal`,
        viewSocial: `${ROOTS.CMS_YOUTUBE_SERVICE}/view/social`,
        viewNativeADS30: `${ROOTS.CMS_YOUTUBE_SERVICE}/view/native-ads-30k`,
        viewNativeADS500: `${ROOTS.CMS_YOUTUBE_SERVICE}/view/native-ads-500k`,
        watchTime: `${ROOTS.CMS_YOUTUBE_SERVICE}/watch-time`,
        comment: `${ROOTS.CMS_YOUTUBE_SERVICE}/comment`,
        commentViet: `${ROOTS.CMS_YOUTUBE_SERVICE}/comment/viet`,
        commentNgoai: `${ROOTS.CMS_YOUTUBE_SERVICE}/comment/ngoai`,
      },

      tiktok: {
        root: `${ROOTS.CMS_TIKTOK_SERVICE}`,
        follow: `${ROOTS.CMS_TIKTOK_SERVICE}/follow`,
        followViet: `${ROOTS.CMS_TIKTOK_SERVICE}/follow/viet`,
        followNhanh: `${ROOTS.CMS_TIKTOK_SERVICE}/follow/nhanh`,
        followNgoai: `${ROOTS.CMS_TIKTOK_SERVICE}/follow/ngoai`,

        likePost: `${ROOTS.CMS_TIKTOK_SERVICE}/likePost`,
        likePostViet: `${ROOTS.CMS_TIKTOK_SERVICE}/likePost/viet`,
        likePostNgoai: `${ROOTS.CMS_TIKTOK_SERVICE}/likePost/ngoai`,

        likeFavorite: `${ROOTS.CMS_TIKTOK_SERVICE}/like-favorite`,

        view: `${ROOTS.CMS_TIKTOK_SERVICE}/view`,

        share: `${ROOTS.CMS_TIKTOK_SERVICE}/share`,

        comment: `${ROOTS.CMS_TIKTOK_SERVICE}/comment`,

        livestream: `${ROOTS.CMS_TIKTOK_SERVICE}/livestream`,
        likeLivestream: `${ROOTS.CMS_TIKTOK_SERVICE}/livestream/like`,
        shareLivestream: `${ROOTS.CMS_TIKTOK_SERVICE}/livestream/share`,
        pkLivestream: `${ROOTS.CMS_TIKTOK_SERVICE}/livestream/pk`,
        viewLivestream: `${ROOTS.CMS_TIKTOK_SERVICE}/livestream/view`,
      },

      instagram: {
        root: `${ROOTS.CMS_INSTAGRAM_SERVICE}`,
        follow: `${ROOTS.CMS_INSTAGRAM_SERVICE}/follow`,
        like: `${ROOTS.CMS_INSTAGRAM_SERVICE}/like`,
        view: `${ROOTS.CMS_INSTAGRAM_SERVICE}/view`,
      },

      twitter: {
        root: `${ROOTS.CMS_TWITTER_SERVICE}`,
        follow: `${ROOTS.CMS_TWITTER_SERVICE}/follow`,
        like: `${ROOTS.CMS_TWITTER_SERVICE}/like`,
        view: `${ROOTS.CMS_TWITTER_SERVICE}/view`,
      },

      telegram: {
        root: `${ROOTS.CMS_TELEGRAM_SERVICE}`,
        member: `${ROOTS.CMS_TELEGRAM_SERVICE}/member`,
        memberViet: `${ROOTS.CMS_TELEGRAM_SERVICE}/member/viet`,
        memberNgoai: `${ROOTS.CMS_TELEGRAM_SERVICE}/member/ngoai`,
      },

      shopee: {
        root: `${ROOTS.CMS_SHOPEE_SERVICE}`,
        like: `${ROOTS.CMS_SHOPEE_SERVICE}/like`,
        follow: `${ROOTS.CMS_SHOPEE_SERVICE}/follow`,
      },

      lazada: {
        root: `${ROOTS.CMS_LAZADA_SERVICE}`,
        like: `${ROOTS.CMS_LAZADA_SERVICE}/like`,
        follow: `${ROOTS.CMS_LAZADA_SERVICE}/follow`,
      },

      google: {
        root: `${ROOTS.CMS_GOOGLE_SERVICE}`,
        reviewMap: `${ROOTS.CMS_GOOGLE_SERVICE}/review-map`,
      },
    },
  },

  // FACEBOOK
  facebook: {
    root: `${ROOTS.FACEBOOK}`,
    likePost: `${ROOTS.FACEBOOK}/like-post`,
    likePost1: `${ROOTS.FACEBOOK}/like-post/server1`,
    likePost2: `${ROOTS.FACEBOOK}/like-post/server2`,
    likePost3: `${ROOTS.FACEBOOK}/like-post/server3`,

    likePage: `${ROOTS.FACEBOOK}/like-page`,
    likePage1: `${ROOTS.FACEBOOK}/like-page/server1`,
    likePage2: `${ROOTS.FACEBOOK}/like-page/server2`,
    likePage3: `${ROOTS.FACEBOOK}/like-page/server3`,
    likePage4: `${ROOTS.FACEBOOK}/like-page/server4`,

    follow: `${ROOTS.FACEBOOK}/follow`,
    follow1: `${ROOTS.FACEBOOK}/follow/server1`,
    follow2: `${ROOTS.FACEBOOK}/follow/server2`,
    follow3: `${ROOTS.FACEBOOK}/follow/server3`,

    friend: `${ROOTS.FACEBOOK}/friend`,

    comment: `${ROOTS.FACEBOOK}/comment`,
    comment1: `${ROOTS.FACEBOOK}/comment/server1`,
    comment2: `${ROOTS.FACEBOOK}/comment/server2`,
    comment3: `${ROOTS.FACEBOOK}/comment/server3`,

    likeComment: `${ROOTS.FACEBOOK}/like-comment`,

    liveStream: `${ROOTS.FACEBOOK}/optic-live`,
    liveStream1: `${ROOTS.FACEBOOK}/optic-live/server1`,
    liveStream2: `${ROOTS.FACEBOOK}/optic-live/server2`,
    liveStream3: `${ROOTS.FACEBOOK}/optic-live/server3`,

    reviewPage: `${ROOTS.FACEBOOK}/review-page`,

    checkInPage: `${ROOTS.FACEBOOK}/check-in-page`,

    vipLike: `${ROOTS.FACEBOOK}/vip-like`,
    vipLike1: `${ROOTS.FACEBOOK}/vip-like/server1`,
    vipLike2: `${ROOTS.FACEBOOK}/vip-like/server2`,

    vipLikeGroup: `${ROOTS.FACEBOOK}/vip-like-group`,

    vipComment: `${ROOTS.FACEBOOK}/vip-comment`,

    vipLive: `${ROOTS.FACEBOOK}/vip-live`,

    view: `${ROOTS.FACEBOOK}/view`,
    vipView: `${ROOTS.FACEBOOK}/view/vip`,
    normalView: `${ROOTS.FACEBOOK}/view/normal`,
    view600k: `${ROOTS.FACEBOOK}/view/600k`,

    sharePostGroup: `${ROOTS.FACEBOOK}/share-post-group`,

    sharePostWall: `${ROOTS.FACEBOOK}/share-post-wall`,

    memberGroup: `${ROOTS.FACEBOOK}/member-group`,
    memberGroup1: `${ROOTS.FACEBOOK}/member-group/server1`,
    memberGroup2: `${ROOTS.FACEBOOK}/member-group/server2`,
  },

  // YOUTUBE
  youtube: {
    root: `${ROOTS.YOUTUBE}`,
    subscribe: `${ROOTS.YOUTUBE}/subscribe`,
    subscribeViet: `${ROOTS.YOUTUBE}/subscribe/viet`,
    subscribeNgoai: `${ROOTS.YOUTUBE}/subscribe/ngoai`,
    likeVideo: `${ROOTS.YOUTUBE}/like-video`,
    dislikeVideo: `${ROOTS.YOUTUBE}/dislike-video`,
    likeComment: `${ROOTS.YOUTUBE}/like-comment`,
    view: `${ROOTS.YOUTUBE}/view`,
    viewNormal: `${ROOTS.YOUTUBE}/view/normal`,
    viewSocial: `${ROOTS.YOUTUBE}/view/social`,
    viewNativeADS30: `${ROOTS.YOUTUBE}/view/native-ads-30k`,
    viewNativeADS500: `${ROOTS.YOUTUBE}/view/native-ads-500k`,
    watchTime: `${ROOTS.YOUTUBE}/watch-time`,
    comment: `${ROOTS.YOUTUBE}/comment`,
    commentViet: `${ROOTS.YOUTUBE}/comment/viet`,
    commentNgoai: `${ROOTS.YOUTUBE}/comment/ngoai`,
  },

  // TIKTOK
  tiktok: {
    root: `${ROOTS.TIKTOK}`,
    follow: `${ROOTS.TIKTOK}/follow`,
    followViet: `${ROOTS.TIKTOK}/follow/viet`,
    followNhanh: `${ROOTS.TIKTOK}/follow/nhanh`,
    followNgoai: `${ROOTS.TIKTOK}/follow/ngoai`,

    likePost: `${ROOTS.TIKTOK}/likePost`,
    likePostViet: `${ROOTS.TIKTOK}/likePost/viet`,
    likePostNgoai: `${ROOTS.TIKTOK}/likePost/ngoai`,

    likeFavorite: `${ROOTS.TIKTOK}/like-favorite`,

    view: `${ROOTS.TIKTOK}/view`,

    share: `${ROOTS.TIKTOK}/share`,

    comment: `${ROOTS.TIKTOK}/comment`,

    livestream: `${ROOTS.TIKTOK}/livestream`,
    likeLivestream: `${ROOTS.TIKTOK}/livestream/like`,
    shareLivestream: `${ROOTS.TIKTOK}/livestream/share`,
    pkLivestream: `${ROOTS.TIKTOK}/livestream/pk`,
    viewLivestream: `${ROOTS.TIKTOK}/livestream/view`,
  },

  // INSTAGRAM
  instagram: {
    root: `${ROOTS.INSTAGRAM}`,
    follow: `${ROOTS.INSTAGRAM}/follow`,
    like: `${ROOTS.INSTAGRAM}/like`,
    view: `${ROOTS.INSTAGRAM}/view`,
  },

  // twitter
  twitter: {
    root: `${ROOTS.TWITTER}`,
    follow: `${ROOTS.TWITTER}/follow`,
    like: `${ROOTS.TWITTER}/like`,
    view: `${ROOTS.TWITTER}/view`,
  },

  // telegram
  telegram: {
    root: `${ROOTS.TELEGRAM}`,
    member: `${ROOTS.TELEGRAM}/member`,
    memberViet: `${ROOTS.TELEGRAM}/member/viet`,
    memberNgoai: `${ROOTS.TELEGRAM}/member/ngoai`,
  },

  shopee: {
    root: `${ROOTS.SHOPEE}`,
    like: `${ROOTS.SHOPEE}/like`,
    follow: `${ROOTS.SHOPEE}/follow`,
  },

  lazada: {
    root: `${ROOTS.LAZADA}`,
    like: `${ROOTS.LAZADA}/like`,
    follow: `${ROOTS.LAZADA}/follow`,
  },

  google: {
    root: `${ROOTS.GOOGLE}`,
    reviewMap: `${ROOTS.GOOGLE}/review-map`,
  },
};
